h1,
h2,
h3,
h4,
p,
span,
div {
    font-family: Montserrat;
    color: #1e3e30;
    margin: 0;

    .italic {
        font-style: italic;
        font-size: inherit;
        line-height: inherit;
    }
}

h1 {
    font-size: 40px;
    font-weight: 500;
    line-height: 48px;

    .italic {
        font-weight: 300;
    }
}

h2 {
    font-size: 32px;
    font-weight: 500;
    line-height: 41.6px;

    .italic {
        font-weight: 400;
    }
}

h3 {
    font-size: 22px;
    font-weight: 500;
    line-height: 28.6px;

    .italic {
        font-weight: 300;
    }
}

h4 {
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;

    .italic {
        font-weight: 400;
    }
}

p {
    font-size: 16px;
    font-weight: 500;
    line-height: 24.8px;
}

a {
    text-decoration: none;
}

p {
    a {
        color: #3e8364;
        text-decoration: underline;
    }
}

.subtitle {
    margin: auto;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(0, 0, 0, 0.4);
}

.text-center {
    text-align: center;
}

.color-white {
    color: white !important;
}
