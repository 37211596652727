@use "./styles/index.scss";

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$clientApp-primary: mat.define-palette(mat.$indigo-palette);
$clientApp-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$clientApp-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$clientApp-theme: mat.define-light-theme(
    (
        color: (
            primary: $clientApp-primary,
            accent: $clientApp-accent,
            warn: $clientApp-warn
        ),
        typography: mat.define-typography-config(),
        density: 0
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($clientApp-theme);

body {
    margin: 0;
}

.input-container {
    display: flex;
    flex-direction: column;
    gap: 8px;

    label {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 500;
        line-height: 25.6px;
    }

    input,
    textarea, select {
        display: block;
        padding: 17px 16px;
        background-color: #3e83641a;
        color: #1e3e30;
        border: none;
        border-radius: 8px;

        font-family: Montserrat;
        font-size: 16px;
        font-weight: 500;
        line-height: 25.6px;

        &:focus {
            outline: none;
        }
    }
}
// @tailwind base;
// @tailwind components;
// @tailwind utilities;

// h1 {
//     font-family: Montserrat;
//     font-size: 40px;
//     font-weight: 500;
//     line-height: 48px;
//     letter-spacing: 0em;
//     text-align: left;

//     .italic {
//         font-family: Montserrat;
//         font-size: 40px;
//         font-style: italic;
//         font-weight: 300;
//         line-height: 48px;
//         letter-spacing: 0em;
//         text-align: left;
//     }
// }

// h2 {
//     max-width: 360px;
//     margin: 64px auto;
//     font-family: Montserrat;
//     font-size: 40px;
//     font-weight: 500;
//     line-height: 52px;
//     letter-spacing: 0em;
//     text-align: center;
//     color: #1e3e30;

//     .italic {
//         font-family: Montserrat;
//         font-size: 40px;
//         font-style: italic;
//         font-weight: 400;
//         line-height: 52px;
//         letter-spacing: 0em;
//         text-align: center;
//         color: #3e8364;
//     }
// }

html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}
